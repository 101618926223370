import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 导入全局组件——插件式
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "animate.css/animate.min.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "normalize.css";
import "@/assets/styles/common.less";
import "babel-polyfill";
import "./utils/rem.js";
// 全局注册
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  createApp(App).component(key, component);
}
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

router.beforeEach((to, from, next) => {
  if((typeof to.params.id !== 'undefined') && location.href.indexOf('desktop') !== -1){
    //console.log(to.params.id);
    switch (to.params.id) {
      case "4" :
        to.meta = to.meta.workspace;
        break;
      case "2" :
        to.meta = to.meta.computer;
        break;
      case "9" :
        to.meta = to.meta.server;
        break;
      case "5" :
        to.meta = to.meta.onemachine;
        break;
      case "3" :
        to.meta = to.meta.notebook;
        break;
    }
  }
  /* 路由发生变化修改页面meta */
  if(to.meta.keywords){
    let meta = document.querySelector('meta[name="keywords"]');
    meta.setAttribute('content',to.meta.keywords);
  }
  if(to.meta.description){
    let meta = document.querySelector('meta[name="description"]');
    meta.setAttribute('content',to.meta.description);
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});

createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { locale, size: "small", zIndex: 3000 });
app.config.warnHandler = () => null;
app.mount("#app");
