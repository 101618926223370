import { createRouter, createWebHashHistory } from "vue-router";
// import HomePage from "../views/home/HomePage.vue";

const HomePage = () => import("@/views/home/HomePage");
const HardDiskPage = () => import("@/views/hard/HardDiskPage");
const DesktopComputer = () => import("@/views/desktop/DesktopComputer");
const DetailsGoodsPage = () => import("@/views/detailsGoods/DetailsGoodsPage");
const DetailsGoodsPageNew = () => import("@/views/detailsGoodsNew/DetailsGoodsPage");
// const ProfessionSoft = () => import("@/views/profession/professionSoft");
const ProfessionSoft = () => import("@/views/profession/index");
const SolutionsIndex = () => import("@/views/solutions/SolutionsIndex");
//const SupportIndex = () => import("@/views/support/SupportIndex");
const SupportIndex = () => import("@/views/supportNew/SupportIndex");
//const AboutUsIndex = () => import("@/views/aboutUs/AboutUsIndex");
const AboutUsIndex = () => import("@/views/aboutUsNew/index");
const GoodsCompare = () => import("@/views/goodsCompare/index");
const GoShopping = () => import("@/views/goShopping/index");
const PayOrder = () => import("@/views/order/payorder");
const billopenInfo = () => import("@/views/billopenInfo/index");
const ConfigOrderSetting = () => import("@/views/order/configordersetting");
const ordersettingcopy = () => import("@/views/order/configordersettingcopy1");
const searchgoodinfo = () => import("@/views/searchgoodinfo/index");
const FillInAdress = () => import("@/views/order/fillinadress");
const InvoiceInfo = () => import("@/views/order/invoiceInfo");
const SupportSonIndex = () => import("@/views/supportSon/SupportSonIndex");
const SupportSonIndex1 = () => import("@/views/supportSon/SupportSonIndex1");
const SaleDetail = () => import("@/views/supportSon/SaleDetail");
const SolveIndex = () => import("@/views/solve/SolveIndex");
const MessageCenter = () => import("@/views/messageCenter/index");
const OrderCenterIndex = () =>
  import("@/views/orderCenter/OrderCenterIndex.vue");
const MessageDetails = () => import("@/views/messageDetails/index.vue");
const PutSomeing = () => import("@/components/putSomeing.vue");
const AfterSaleList = () => import("../views/AfterSaleList/index.vue");
//const Customize = () => import("../views/Customize/index.vue");
const Customize = () => import("../views/CustomizeNew/index.vue");
const CommercialProduct = () => import("../views/commercialProduct/index.vue");
const SolutionsChildren = () => import("../views/solutionsChildren/index.vue");
const Scheme = () => import("../views/scheme/index.vue");
const CouponList = () => import("../views/coupon/CouponList.vue");
const CouponDetail = () => import("../views/coupon/CouponDetail.vue");
const WorkOrderFilling = () => import("../views/workOrderFilling/index.vue")
const Promotion = () => import("../views/promotion/index.vue")
const AfterSalePolicy = () => import("../views/supportNew/components/AfterSalePolicy.vue")
const SelectScene = () => import("../views/buy/selectScene/index.vue")
const ConfirmConfig = () => import("../views/buy/confirmConfig/index.vue")
const AfterDelivery = () => import("../views/supportNew/components/AfterDelivery.vue")

// 定义路由列表
const routes = [
  { path: "/", component: HomePage },
  { path: "/hard", component: HardDiskPage }, // 联系技术专家
  { path: "/desktop/:id", component: DesktopComputer,
    meta: {
      workspace: {
        title: "WS系列工作站",
        keywords: "工作站,专业工作站,高性能工作站,WS系列工作站,行业工作站,软件工作站",
        description: "专业的高性能工作站,提高您的生产力.高性能计算能力,并行计算支持,专用硬件加速器,大规模数据处理能力,稳定可靠的运行环境,虚拟化和远程管理 高度可扩展性和优化的软件支持,研发效率高,数据安全可靠,为科学计算工作提供强大的计算资源和工作环境."
      },
      computer: {
        title: "CR系列台式机",
        keywords: "台式机,专业台式机,高性能台式机,性价比首选,企业采购首选",
        description: "专业的高性能台式机,提高您的生产力.高效节能,性能主流,外观小巧,兼具性价比,接口丰富,安静运行人体工学设计,简易安装,售后方便,质量可靠, 为您提供优秀的办公娱乐体验."
      },
      notebook: {
        title: "笔记本",
        keywords: "高性能超薄笔记本,商务笔记本,办公笔记本,高性能笔记本,高性价比笔记本",
        description: "专业的高性能笔记本,提高您的生产力.高效节能,性能主流,外观小巧,兼具性价比,金属机身,商务办公,质量可靠,为您提供优秀的办公娱乐体验."
      },
      onemachine: {
        title: "一体机",
        keywords: "一体机,学习办公一体机,办公电脑,网课电脑,高性价比一体机",
        description: "专业的学习办公一体机,高清网课学习办公电脑,质量可靠."
      },
      server: {
        title: "服务器",
        keywords: "服务器,高算力服务器,高存储服务器,超强性能,先进存储,企业级完全防护,安全稳定可靠运行服务器",
        description: "专业的高算力服务器,多存储、高算力、重安全的多面手,助力企业驰骋AI时代,决胜云端."
      }
    }
  }, // 商用产品 下面的
  {
     path: "/detailsGoods",
     name: "detailsGoods",
     component: DetailsGoodsPage,
  }, // 商品详情
  { path: "/detailsGoodsNew",name: "detailsGoodsNew", component: DetailsGoodsPageNew },
  { path: "/profession/:id", component: ProfessionSoft }, // 联系技术专家
  { path: "/solutions", component: SolutionsIndex,
    meta: {
      title: "合作案例",
      keywords: "合作案例,成功案例,商用案例,解决方案,行业案例,行业解决方案,应用成果,合作伙伴",
      description: "宁美商用平台,专精商用办公领域,用创新的产品和智慧体验满足全球用户的差异化需求,为中小企业提供一站式硬件解决方案"
    }
  }, //企业解决方案
  { path: "/aboutUs", component: AboutUsIndex,
    meta: {
      title: "宁美商用官方网站",
      keywords: "宁美品牌官网,宁美商用官方网站,关于宁美,品牌介绍,全球市场,智能工厂,合作伙伴",
      description: "宁美是中国首屈一指的企业硬件解决方案的领先企业,专注电脑硬件领域14年,拥有大量硬件解决方案的成功经验和案例.自2010年正式成立以来,宁美的专业产品及服务赢得了全球千万用户的认可.2024年,宁美创立宁美商用平台,专精商用办公领域,用创新的产品和智慧体验满足全球用户的差异化需求,为中小企业提供一站式硬件解决方案.宁美商用应势而谋,顺势而动,聚势发力,勇做行业的革新者."
    }
  }, // 了解我们
  { path: "/support", component: SupportIndex,
    meta: {
        title: "宁美售后客户服务中心,提供网点查询,保修服务,服务政策查询服务,售后热门问题咨询",
        keywords: "宁美客户服务中心,宁美售后服务,宁美快速报修,宁美售后问题咨询",
        description: "宁美服务官网为宁美TD系统、宁美CR系统、宁美WS系统等系列产品提供专业的技术支持和售后服务.具体有:驱动下载、Win10驱动、售后服务网点查询、保修配置查询、驱动查询、驱动光盘安装、主机编号查询、宁美知识库、常见问题帮助、故障诊断、网上报修、网上预约、WIN8支持、WIN10支持."
    }
  }, // 服务与技术支持
  { path: "/afterSalePolicy", component: AfterSalePolicy }, // 售后服务政策
  { path: "/billopenInfo", component: billopenInfo },
  { path: "/goodsCompare", component: GoodsCompare },
  { path: "/payorder", component: PayOrder }, // 订单与支付
  { path: "/fillinadress", component: FillInAdress }, // 订单与支付
  { path: "/invoiceInfo", component: InvoiceInfo }, // 订单与支付
  { path: "/messageCenter", component: MessageCenter }, // 订单与支付
  { path: "/putsomeing", component: PutSomeing },
  { path: "/goShopping", component: GoShopping },
  { path: "/searchgoodinfo", component: searchgoodinfo },
  { path: "/configordersettingcopy1", component: ordersettingcopy },
  {
    path: "/configordersetting",
    name: "submitOrder",
    component: ConfigOrderSetting,
  }, // 订单与支付
  { path: "/supportSon/:id", component: SupportSonIndex }, // 服务于支持页面
  { path: "/supportSontwo/:id", component: SupportSonIndex1 }, // 服务于支持页面
  { path: "/SaleDetail/:id", component: SaleDetail }, // 服务于支持页面
  { path: "/solve/:id/:domainid", component: SolveIndex }, // 行业软件解决方案
  { path: "/orderCenter", component: OrderCenterIndex }, // 我的订单
  { path: "/messageDetails/:id", component: MessageDetails },
  { path: "/AfterSaleList", component: AfterSaleList }, // 售后列表
  { path: "/Customize", component: Customize,
    meta: {
      title: "硬件定制",
      keywords: "全行业解决方案定制,行业软件方案定制,办公场景硬件定制,个性化定制服务,私人定制,定制电脑,专业解决方案,定制工作站,电脑DIY,来图定制,行业定制解决方案,硬件定制,一对一客户咨询服务",
      description: "针对不同的办公使用场景,为您精选高性能和高质量的硬件方案.宁美致力于研发和生产各领域行业解决方案,为你的企业和个人重塑生产力.针对您的生产力需求,我们的咨询顾问为您定制专业解决方案.",
    },
  }, // 定制方案
  { path: "/commercialProduct", component: CommercialProduct,
    meta: {
      title: "商用办公电脑",
      keywords: "商用办公电脑,台式机,工作站,服务器,笔记本,一体机,高性能电脑,专业工作站,高性能工作站,WS系列工作站,CR系列台式机,TD系列台式机",
      description: "专业的高性能电脑和办公设备,提高您的生产力.宁美是中国首屈一指的企业硬件解决方案的领先企业,专注电脑硬件领域14年,拥有大量硬件解决方案的成功经验和案例.自2010年正式成立以来,宁美的专业产品及服务赢得了全球千万用户的认可."
    }
  }, // 商用产品
  { path: "/solutionsChildren/:id", component: SolutionsChildren }, // 合作案例详情页面
  { path: "/Scheme", component: Scheme,
    meta: {
      title: "方案定制",
      keywords: "个性化定制服务,私人定制,定制电脑,专业解决方案,定制工作站,电脑DIY,来图定制,行业定制解决方案,硬件定制,一对一客户咨询服务",
      description: "宁美致力于研发和生产各领域行业解决方案,为你的企业和个人重塑生产力.针对您的生产力需求,我们的咨询顾问为您定制专业解决方案.",
    },
  }, //方案定制页面
  { path: "/couponList", component: CouponList }, // 优惠信息页面
  { path: "/couponDetail/:id/:receiveId/:state", component: CouponDetail }, // 优惠券详情页面
  { path: "/workOrderFilling", component: WorkOrderFilling }, // 工单填写
  { path: "/promotion", component: Promotion }, // 工单填写
  { path: "/selectScene", component: SelectScene }, // 选择场景
  { path: "/confirmConfig", component: ConfirmConfig },
  { path: "/afterDelivery", component: AfterDelivery }, // 交付售后
  /* 404 */
  {
    path: "/notfound",
    component: () => import("@/views/NotFound"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/notfound",
  },
];

// 创建并生成路由
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // 每次跳转返回顶部
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  // 定义【模糊匹配】和【精确匹配】激活类名
  linkActiveClass: "fuzzy-active",
  linkExactActiveClass: "exact-active",
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
