import request from "@/utils/request";

// 商用产品
export function EnterpriseIT() {
  return request.get("/goods/production/listForPlatform");
  // return request.get("/production/listForPlatform");
}

// 行业软件方案
export function Software() {
  return request.get("/goods/software/listDomain");
  // return request.get("/software/listDomain");
}

export function shopCount() {
  return request.get("/order/cart/count");
}

// 按行业软件选择
export function SoftwareList({ keyWords }) {
  return request.get(`/goods/software/listByKeyWords?`, { keyWords });
  // return request.get(`/software/listByKeyWords?`, { keyWords });
}

export function machineBanner(machineCode) {
  return request.get("/goods/banner/queryBanner/" + machineCode);
}

export function queryContent(machineCode) {
  return request.get("/goods/content/queryContent/" + machineCode);
}

export function checkSafe(query) {
  return request.get("/goods/check/safe", query);
}

// 查询关联首页行业下的行业
export function softwareIndustry(domainParent){
  return request.get("/goods/software/industry/" + domainParent);
}
